.addAnimation, .addAnimationStep { visibility: hidden; }

@mixin animation-thing($name, $speed: 1s) {
	.#{$name} {
		animation-name: $name;
		animation-duration: $speed;	
		animation-timing-function: ease;
		visibility: visible !important;
	}
}

@mixin animation-thing-hide($name, $speed: 1s) {
	.#{$name} {
		animation-name: $name;
		animation-duration: $speed;	
		animation-timing-function: ease;
		visibility: hidden !important;
	}
}

@include animation-thing(fadeInRight);
@include animation-thing(fadeInLeft);
@include animation-thing(fadeInDown);
@include animation-thing(fadeInUp);
@include animation-thing(fadeIn);

@include animation-thing(zoomInRight, 2s);
@include animation-thing(zoomInLeft, 2s);
@include animation-thing(zoomInDown, 2s);
@include animation-thing(zoomInUp, 2s);
@include animation-thing(zoomIn);

@include animation-thing(rubberBand);
@include animation-thing(pulse);
@include animation-thing(pulsemax);
@include animation-thing(bounce);

@import "_animate_css.scss";