@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;800&display=swap');

@import "assets/normalize";
@import "assets/variables";
@import "assets/fixers";
@import "assets/mixins";
@import "assets/animations";

html, body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    scroll-behavior: smooth;
}

.overhide {
    width: 100%;
    overflow: hidden;
}

.container {
    width: 90%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
}

.site-header {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-3 0;
        font-size: 16px;

        .logo {
            display: block;
            width: 60%;
            max-width: 325px;

            svg, img {
                display: block;
                width: 100%;
            }
        }

        .navigation {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;

            li {
                &:not(:last-child) { margin-right: $spacing-4; }
            }

            a {
                text-decoration: none;
                font-weight: 600;
                color: $black;

                &:hover {
                    color: $accent;
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            padding: $spacing-2 0;

            .logo {
                display: block;
                margin: 0 auto;
            }

            .navigation {
                display: none;
            }
        }
    }

}

.typo-rules {
    line-height: 1.6;

    > * {
        margin: $spacing-4 0;

        &:first-child { margin-top: 0px; }
        &:last-child { margin-bottom: 0px; }
    }
}

.btn {
    display: inline-block;
    padding: $spacing-3 $spacing-4;
    background-color: $accent;
    color: $white;
    text-decoration: none;
    border-radius: $spacing-05;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;

    &.gold {
        background: linear-gradient(45deg, #DFA858, #FAD585);
    }
}

.section-title {

    .title {
        margin: 0;
        font-size: 48px;
        font-weight: 600;
        color: $accent;
    }

    .subtitle {
        margin: $spacing-1 0 0 0;
        line-height: 1.5;
        font-weight: 600;
        color: #555;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 150px;
        height: 3px;
        background: linear-gradient(to right, #DFA858, #FAD585);
        margin: $spacing-3 0;
    }

    &.center {
        text-align: center;

        &:after {
            margin: $spacing-3 auto;
        }
    }

    @media (max-width: $screen-xs-max) {
        .title {
            font-size: 36px;
        }
    }
}

.hero-panel {
    display: block;
    width: 100%;
    padding: 250px 0;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(black, 0), rgba(black, 0.5));
    }

    .container {
        backdrop-filter: blur(4px);
        border: 2px solid rgba(white, 0.75);
        border-radius: $spacing-05;
        text-align: center;
        padding: $spacing-5 $spacing-5 $spacing-6 $spacing-5;

        .title {
            margin: 0 0 $spacing-3 0;
            color: $white;
            font-weight: 700;
            font-size: 60px;
        }
    }

    @media (max-width: $screen-sm-max) {
        padding: 150px 0;

        .container {
            .title {
                font-size: 48px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 100px 0;

        .container {
            .title {
                font-size: 36px;
            }
        }
    }
}

.intro-panel {
    margin: 100px 0;

    @media (max-width: $screen-xs-max) {
        margin: 75px 0;
    }
}

.what-panel {
    background-color: #f7f7f7;

    &:before, &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, #DFA858, #FAD585);
    }

    .container {
        padding: 100px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            width: calc(55% - 32px);
        }

        .image {
            width: calc(45% - 32px);
        }
    }

    @media (max-width: $screen-sm-max) {
        .container {
            display: block;

            .content {
                width: 100%;
                margin-bottom: $spacing-4;
                text-align: center;
            }

            .image {
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        .container {
            padding: 75px 0;
        }
    }
}

.about-panel {
    overflow: hidden;

    .container {
        padding-top: 75px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .social {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
            }

            li:not(:last-child) { margin-right: $spacing-3; }

            a {
                svg {
                    width: $spacing-5;
                    height: $spacing-5;

                    * {
                        fill: $accent;
                    }
                }
            }
        }

        .content {
            width: calc(50% - 32px);
            padding-bottom: 100px;
            z-index: 20;
        }

        .image {
            width: calc(50% - 32px);

            img {
                display: block;
                z-index: 5;
            }
        }

        .testimonial {
            background-color: $accent;
            color: white;

            &:before {
                content: "";
                display: block;
                width: 200vw;
                height: 100%;
                position: absolute;
                top: 0; right: calc(100% + 25px);
                background-color: #eee;
                transform-origin: top right;
                transform: rotate(-20deg);
            }

            &:after {
                content: "";
                display: block;
                width: 200vw;
                height: 100%;
                position: absolute;
                top: 0; left: -25px;
                background-color: $accent;
                transform: rotate(-20deg);
                transform-origin: top left;
            }

            blockquote {
                margin: 0;
                padding: 50px 0 50px 24px;
                z-index: 5;

                &:before {
                    content: "";
                    display: block;
                    width: 125px;
                    height: 125px;
                    position: absolute;
                    top: 0px; left: -10px;
                    z-index: 100;
                    background-image: url('../images/quote.svg');
                    background-size: 100% 100%;
                    opacity: 0.1;
                }

                > * {
                    z-index: 5;
                }

                &:after {
                    content: "";
                    display: block;
                    width: 100vw;
                    height: 100%;
                    position: absolute;
                    top: 0; left: -25px;
                    background-color: $accent;
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        .container {
            display: block;

            .content {
                width: 100%;
                text-align: center;
                padding-bottom: 50px;
            }

            .image {
                width: 100%;

                > img {
                    display: block;
                    width: 100%;
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .testimonial {
                &:before {
                    background-color: $accent;
                }
            }
        }
    }
}

.logo-panel {

    &:before, &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, #DFA858, #FAD585);
    }

    .container {
        padding: 100px 0;
    }

    .logos {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            width: 20%;
            margin: $spacing-2 0;
            padding: 0 $spacing-4;
        }
    }

    @media (max-width: $screen-sm-max) {
        .container {
            padding: 75px 0;
        }

        .logos li {
            width: 25%;
        }
    }

    @media (max-width: $screen-xs-max) {
        .container {
            padding: 50px 0;
        }

        .logos li {
            width: 33%;
            margin: $spacing-2 0;
            padding: 0 $spacing-2;
        }
    }
}

.main-footer {
    background-color: $accent;
    padding: 100px 0;
    color: white;

    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .left {
            width: 50%;
            padding-right: $spacing-4 * 2;
            border-right: 1px dotted white;
        }

        .right {
            width: 50%;
            padding-left: $spacing-4 * 2;
        }
    }

    .top {
        width: 100%;
        border-bottom: 1px dotted white;
        padding-bottom: $spacing-4;
        margin-bottom: $spacing-4;

        .logo {
            display: block;
            width: 100%;
            max-width: 350px;

            svg, img {
                display: block;
                width: 100%;

                * {
                    fill: white;
                }
            }
        }
    }

    .left, .right {

        .description {
            margin: 0 0 $spacing-4 0;
        }

        .social {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li:not(:last-child) { margin-bottom: $spacing-2; }

            a {
                display: flex;
                align-items: center;

                svg, img {
                    width: $spacing-3;
                    height: $spacing-3;
                    margin-right: $spacing-1;

                    * {
                        fill: white;
                    }
                }

                color: white;
                text-decoration: none;
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        padding: 75px 0;

        .container {
            display: block;

            .left {
                width: 100%;
                padding-right: 0;
                border-right: none;
                border-bottom: 1px dotted white;
                padding-bottom: $spacing-3;
                margin-bottom: $spacing-3;
            }

            .right {
                width: 100%;
                padding-left: 0;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding: 50px 0;
    }
}