// Give all elements Box Sizing - Border-Box //
*, *:before, *:after {
	position: relative;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

::selection {
	background: $black; /* WebKit/Blink Browsers */
	color: $white;
}

::-moz-selection {
	background: $black; /* Gecko Browsers */
	color: $white;
}

  // Clearfix class
.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

// Remove lines from mozilla browser links
:focus {outline:none;}
::-moz-focus-inner {border:0;}

// Give Clickable Elements a Pointer Cursor //
a[href], input[type='submit'], input[type='image'], label[for], select, button, .pointer {
    cursor: pointer;
}

img {
	max-width: 100%;
}

img.hideme {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

a, a:visited, a:focus, a:active, a:hover{
    outline:0 none !important;
}

iframe {
	border: 0;
}

// Add transition to links
a, input[type="submit"], button {
	outline: none;

	&:hover, &:focus {
		outline: none;
	}
}

input, textarea, select {
	// appearance: none;
	border-radius: 0px;

	&:hover, &:focus {
		outline: none;
	}
}

.paf {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 100%;
}

a {
	transition: all ease 0.3s;
}